import styled from "styled-components/macro";

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.fullHeight ? "100%" : "70%")};
  width: 100%;
  & img {
    object-fit: cover;
  }
`;

export const CardContentFront = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.isImage ? "0" : "16px")};
  position: relative;
`;

export const CardContentBack = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
`;

export const IconWrapper = styled.div`
  flex: 3;
`;

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  position: absolute;
  cursor: pointer;
`;

export const BackWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  width: 90%;
  height: 120px;
  margin-top: 5px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
